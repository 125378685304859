import * as React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { styled } from '@naan/stitches.config';
export type AfterActionCallback = () => void | boolean;
export type ActionCallback = (after?: AfterActionCallback) => void | boolean;

export type MenuItemProps = {
  label: React.ReactNode;
  action: ActionCallback;
  afterAction?: AfterActionCallback;
  disabled?: boolean;
  isDestructive?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};

const StyledItem = styled(DropdownMenu.Item, {
  $$itemColor: '$colors$textPrimary',
  $$iconColor: '$colors$gray-400',

  all: 'unset',
  padding: '16px',
  '@media screen and (max-height: 534px)': {
    padding: '12px 16px',
  },
  position: 'relative',
  cursor: 'pointer',
  userSelect: 'none',
  textStyle: 'body',
  display: 'flex',
  justifyContent: 'flex-start',
  color: '$$itemColor',

  '&:not(:last-of-type)::after': {
    position: 'absolute',
    content: '',
    left: '16px',
    right: '16px',
    bottom: 0,
    height: '1px',
    background: '$colors$black-alpha-10',
  },

  '&[data-disabled]': {
    $$itemColor: '$colors$gray-300',
    $$iconColor: '$colors$gray-200',
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },

  '&:focus': {
    $$itemColor: '$colors$teal-600',
    $$iconColor: '$colors$teal-600',
  },

  variants: {
    isSelected: {
      true: {
        $$itemColor: '$colors$teal-500',
        $$iconColor: '$colors$teal-500',
      },
    },
    isDestructive: {
      true: {
        $$itemColor: '$colors$red-500',
        $$iconColor: '$colors$red-300',
      },
    },

    presentation: {
      navItem: {
        $$itemColor: '$colors$textSecondary',
      },
    },

    size: {
      masalaSmall: {
        padding: '8px 16px',
        fontSize: 'small',
      },
    },
  },
});

const LeftIconWrapper = styled('div', {
  color: '$$iconColor',
});

const RightIconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '$$iconColor',
  marginLeft: 'auto',
});

const Spacer = styled('div', {
  width: '$space$3',
});

export const MenuItem = ({
  label,
  // testId,
  disabled,

  action = () => {},
  afterAction = () => {},
  leftIcon,
  rightIcon,
  ...props
}: MenuItemProps & React.ComponentProps<typeof StyledItem>) => {
  const maybeAction = (e: any) => {
    if (disabled) {
      return;
    }
    if (action(afterAction) !== false) {
      afterAction();
    } else {
      e.preventDefault();
    }
  };

  return (
    <StyledItem onSelect={maybeAction} disabled={disabled} {...props}>
      {leftIcon ? (
        <>
          <LeftIconWrapper>{leftIcon}</LeftIconWrapper>
          <Spacer />
        </>
      ) : null}
      {label}
      {rightIcon ? (
        <>
          <Spacer />
          <RightIconWrapper>{rightIcon}</RightIconWrapper>
        </>
      ) : null}
    </StyledItem>
  );
};
