import * as React from 'react';
import { MenuItem } from './menu-item';
import { CouponIcon } from '@naan/icons/coupon-icon';
import { RetryIcon } from '@naan/icons/retry-icon';
import { LifebuoyIcon } from '@naan/icons/lifebuoy-icon';
import { useNavigate } from 'react-router-dom';
import { useCouponDialog } from 'components/coupons/use-coupon-dialog';
import { marketingSiteL1, supportPath } from '../path-helpers';
import { useAnonymousCouponDialog } from 'components/coupons/use-anonymous-coupon-dialog';
import { AppFactory } from '@app/app-factory';
import { reloadOrNativeReset } from '@core/lib/app-util';
import { SettingsIcon } from '@naan/icons/settings-icon';
import { presentSettingsModal } from 'components/settings';
import { ShareIcon } from '@naan/icons/share-icon';
import { NotificationService } from '@app/notification-service';
import { track } from '@app/track';
import { events } from '@common/instrumentation/events';

import __ from 'core/lib/localization';

export const SharedMenuItems = () => {
  const navigate = useNavigate();

  const navigateToSupport = React.useCallback(() => {
    navigate(supportPath());
  }, [navigate]);

  return (
    <>
      <SettingsMenuItem />
      <PurchaseFlowItems />
      <MenuItem
        label={__('Support and feedback', 'supportAndFeedback')}
        leftIcon={<LifebuoyIcon />}
        presentation="navItem"
        action={navigateToSupport}
      />
      <ShareJiveworldItem />
      <MenuItem
        label={__('Reload', 'reload')}
        leftIcon={<RetryIcon />}
        presentation="navItem"
        action={() => {
          // window.location.reload();
          reloadOrNativeReset(); // attempt webview level reset to dashboard with embed params when embedded
        }}
      />{' '}
    </>
  );
};

const PurchaseFlowItems = () => {
  const { purchaseFlowDisabled, authenticated } = AppFactory.root.userManager;
  const currentPath = window.location.pathname;

  const handleApplyCode = useCouponDialog();
  const showInterstitialCouponDialog = useAnonymousCouponDialog({
    redirectTo: currentPath,
  });
  // const showInterstitialGiftCouponDialog = useAnonymousGiftCouponDialog();

  // todo: better factor this with teach-navigation.tsx
  if (purchaseFlowDisabled) {
    return null;
  }

  return (
    <MenuItem
      label={__('Redeem a code', 'redeemACode')}
      leftIcon={<CouponIcon />}
      presentation="navItem"
      action={authenticated ? handleApplyCode : showInterstitialCouponDialog}
    />
  );
};

const SettingsMenuItem = () => {
  return (
    <MenuItem
      label={__('Settings', 'settings')}
      leftIcon={<SettingsIcon />}
      presentation="navItem"
      action={() => presentSettingsModal()}
    />
  );
};

const ShareJiveworldItem = () => {
  // navigator.share =
  //   navigator.share || ((...args) => Promise.reject(JSON.stringify(args)));
  const { l2Localized } = AppFactory.root;

  const data = React.useMemo(
    () => ({
      title: __('Share Jiveworld', 'shareJiveworld') + '\n',
      // I am no copywriter
      text:
        '\n' +
        __(
          "I'm learning %{l2} as it's really spoken with the Jiveworld app. They use real %{l2} podcast stories featuring different kinds of voices, helping me with vocabulary, local accents, and many other aspects of the language. Have a look!",
          'imLearningL2',
          {
            l2: l2Localized,
          }
        ) +
        '\n',
      url: marketingSiteL1(),
    }),
    [l2Localized]
  );

  const sharingAvailable = navigator.canShare?.(data) ?? false;

  const shareJw = React.useCallback(() => {
    // use borwsers native share api
    navigator
      .share(data)
      .then(() => {
        track(events.shareMenu.shareAction);
        NotificationService.open({
          message: __('Thanks for sharing', 'thanksForSharing'),
          type: 'success',
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error sharing:', error);
      });
  }, [data]);

  if (!sharingAvailable) {
    return null;
  }

  return (
    <MenuItem
      label={__('Share Jiveworld', 'shareJiveworld')}
      leftIcon={<ShareIcon />}
      presentation="navItem"
      action={shareJw}
    />
  );
};
