import * as React from 'react';
import { darkThemeSelector, styled } from 'naan/stitches.config';
import { learnHomePath } from 'components/nav/path-helpers';
import { BrandedBackLink } from 'components/branding/branded-back-link';
import { AppFactory } from '@app/app-factory';

export const AuthLayoutWrapper = styled('div', {
  paddingTop: 'var(--sat)',
  paddingBottom: 'var(--sab)',
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '&:before': {
    content: '""',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: 'var(--sat)',
    zIndex: 1,
    backgroundColor: '$gray-300',
    [darkThemeSelector]: {
      backgroundColor: '$white',
    },
  },
  '& > .back-link': {
    position: 'absolute',
    top: 'calc(16px + var(--sat))',
    left: '16px',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    '@medium': {
      top: 'calc(28px + var(--sat))',
      left: '24px',
    },
  },
  '& > .inner': {
    paddingTop: 'max( 88px, 50vh - 322px )',
    width: 'min( 400px, calc(100% - 32px) )',
    '@medium': {
      paddingBottom: 88,
    },
  },
});

export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  const { accountCreationDisabled } = AppFactory.root;
  return (
    <AuthLayoutWrapper>
      {accountCreationDisabled ? null : (
        <div className="back-link">
          <BrandedBackLink to={learnHomePath()} presentation={'color'} />
        </div>
      )}

      <div className="inner">{children}</div>
      {/* <WideInnerWrapper>
        <GlobalFooter />
      </WideInnerWrapper> */}
    </AuthLayoutWrapper>
  );
};
