import * as React from 'react';
// import { clearEmbedState, simulateEmbedState } from './init-embed-state';

const PREF_KEY = 'jw.simulate-safe-areas';

export const simulateSafeAreas = {
  ':root:has(body.simulate-safe-areas)': {
    '--sat': '60px !important',
    '--sab': '26px !important',
  },
  'body.simulate-safe-areas:before, body.simulate-safe-areas:after': {
    content: '',
    display: 'block',
    position: 'fixed',
    top: 'var(--sat)',
    left: 0,
    right: 0,
    borderBottom: '2px dashed rgba(230, 230, 230, 0.5)',
    zIndex: 9999,
  },
  'body.simulate-safe-areas:after': {
    top: 'auto',
    bottom: 'var(--sab)',
  },
};

export function applySimulateSafeAreasPreference() {
  const simulate = localStorage.getItem(PREF_KEY);

  if (simulate === 'true') {
    document.body?.classList?.add('simulate-safe-areas');
  } else {
    // remove :root css properties
    document.body?.classList?.remove('simulate-safe-areas');
  }
}

export function toggleSimulateSafeAreasPreference() {
  const simulate = localStorage.getItem(PREF_KEY) ?? 'false';
  localStorage.setItem(PREF_KEY, simulate === 'true' ? 'false' : 'true');
  applySimulateSafeAreasPreference();
}

export function getSimulateSafeAreasPreference(): boolean {
  return localStorage.getItem(PREF_KEY) === 'true';
}

export const SimulateSafeArea = (): null => {
  React.useEffect(() => {
    applySimulateSafeAreasPreference();
  }, []);

  return null;
};
