import * as React from 'react';
import { AvatarIcon } from 'naan/icons/avatar-icon';
import { PopoverNavHeader } from 'components/ui/navigation/responsive-navigation';
import { MenuItem, MenuItemContainer } from './menu-item';
import { SignInIcon } from '@naan/icons/sign-in-icon';
import { SignOutIcon } from '@naan/icons/sign-out-icon';
import { Text } from 'naan/primitives/text';
import { useNavigate } from 'react-router-dom';
import {
  createAccountPath,
  logoutPath,
  profileHomePath,
  signInPath,
} from '../path-helpers';
import { AppFactory } from '@app/app-factory';
import {
  clearSavedScrollPosition,
  intentionallyResetAllScrollStorageKeys,
} from 'lib/scroll-utils/deep-scroll-restoration';
import { SharedMenuItems } from './shared-items';
import { NavPopoverMenu } from './nav-popover-menu';
import { AccountMenuTrigger } from './account-trigger';
import { TeachNavButton } from '../external-nav-items';

import __ from 'core/lib/localization';

const AuthMenuContent = () => {
  const { accountData } = AppFactory.root.userManager;

  const navigate = useNavigate();

  const goto = React.useCallback(
    (to: string) => () => navigate(to),
    [navigate]
  );

  const handleLogout = React.useCallback(() => {
    navigate(logoutPath());
    intentionallyResetAllScrollStorageKeys();
  }, [navigate]);

  return (
    <MenuItemContainer>
      <PopoverNavHeader>
        <Text color="textPrimary" textStyle="small-heading">
          {accountData.name}
        </Text>
        <Text color="textSecondary" textStyle="small-text">
          {accountData.email}
        </Text>
      </PopoverNavHeader>
      <MenuItem
        label={__('Manage my account', 'manageMyAccount')}
        leftIcon={<AvatarIcon />}
        presentation="navItem"
        action={goto(profileHomePath())}
      />
      <SharedMenuItems />
      <MenuItem
        label={__('Sign out', 'signOut')}
        leftIcon={<SignOutIcon />}
        presentation="navItem"
        action={handleLogout}
      />
      <TeachNavButton />
    </MenuItemContainer>
  );
};

const AnonMenuContent = () => {
  const navigate = useNavigate();

  const goto = React.useCallback(
    (to: string) => () => {
      clearSavedScrollPosition(to);
      navigate(to);
    },
    [navigate]
  );

  return (
    <MenuItemContainer>
      <MenuItem
        label={__('Create account', 'createAccount')}
        leftIcon={<AvatarIcon />}
        presentation="navItem"
        action={goto(createAccountPath())}
      />
      <MenuItem
        label={__('Sign in', 'signIn')}
        leftIcon={<SignInIcon />}
        presentation="navItem"
        action={goto(signInPath())}
      />

      <SharedMenuItems />
    </MenuItemContainer>
  );
};

export const LearnPopoverMenu = () => {
  const { authenticated } = AppFactory.root.userManager;

  return (
    <NavPopoverMenu trigger={<AccountMenuTrigger />}>
      {authenticated ? <AuthMenuContent /> : <AnonMenuContent />}
    </NavPopoverMenu>
  );
};
