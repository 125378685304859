import * as React from 'react';
import { HomeIcon } from 'naan/icons/home-icon';
import {
  LinkNavItem,
  NavBottomAnchor,
  NavContainer,
  NavLogoWrapper,
} from 'components/ui/navigation/responsive-navigation';
import {
  learnHomePath,
  learnProgressPath,
  learnStoriesPath,
} from './path-helpers';

import { AppFactory } from '@app/app-factory';
import { JwLogo } from 'components/branding/jw-logo';
import { observer } from 'mobx-react';
import { ListIcon } from '@naan/icons/list-icon';
import { PointsIcon } from '@naan/icons/points-icon';
import { LearnPopoverMenu } from './nav-popover-menu';
import { TeachNavButton } from './external-nav-items';

import __ from 'core/lib/localization';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ReactNativeWebView: any;
    embeddedPlatform: string;
  }
}

export const LearnNavigation = observer(() => {
  // const { userManager: { classroomEnabled } } = AppFactory.root;
  const { userManager, storyManager } = AppFactory.root;

  const l2Switch = userManager.anonymous;

  return (
    <NavContainer>
      <NavLogoWrapper>
        <JwLogo subBrand={storyManager.subBrand()} l2Switch={l2Switch} />
      </NavLogoWrapper>

      <LinkNavItem
        to={learnHomePath()}
        icon={<HomeIcon />}
        label={__('Home', 'home')}
      />
      <LinkNavItem
        to={learnStoriesPath()}
        icon={<ListIcon />}
        label={__('Stories', 'stories')}
      />

      <LinkNavItem
        to={learnProgressPath()}
        icon={<PointsIcon />}
        label={__('My stats', 'myStats')}
      />
      <NavBottomAnchor>
        <TeachNavButton />
        <LearnPopoverMenu />
      </NavBottomAnchor>
    </NavContainer>
  );
});
