import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { usePopoverContext } from './nav-popover-menu';

export type AfterActionCallback = () => void | boolean;
export type ActionCallback = (after?: AfterActionCallback) => void | boolean;

export type MenuItemProps = {
  label: React.ReactNode;
  action: ActionCallback;
  afterAction?: AfterActionCallback;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};

const StyledItem = styled('button', {
  $$itemColor: '$colors$textPrimary',
  $$iconColor: '$colors$gray-400',

  all: 'unset',
  flex: 1,
  padding: '16px',
  '@media screen and (max-height: 534px)': {
    padding: '12px 16px',
  },
  position: 'relative',
  cursor: 'pointer',
  userSelect: 'none',
  textStyle: 'body',
  display: 'flex',
  justifyContent: 'flex-start',
  flexGrow: 0,
  color: '$$itemColor',

  '&:not(:last-of-type)::after': {
    position: 'absolute',
    content: '',
    left: '16px',
    right: '16px',
    bottom: 0,
    height: '1px',
    background: '$colors$black-alpha-10',
  },

  '&[data-disabled]': {
    $$itemColor: '$colors$gray-300',
    $$iconColor: '$colors$gray-200',
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },

  '&:focus': {
    $$itemColor: '$colors$teal-600',
    $$iconColor: '$colors$teal-600',
  },

  variants: {
    isSelected: {
      true: {
        $$itemColor: '$colors$teal-500',
        $$iconColor: '$colors$teal-500',
      },
    },
    isDestructive: {
      true: {
        $$itemColor: '$colors$red-500',
        $$iconColor: '$colors$red-300',
      },
    },

    presentation: {
      navItem: {
        $$itemColor: '$colors$textSecondary',
      },
    },

    size: {
      masalaSmall: {
        padding: '8px 16px',
        fontSize: 'small',
      },
    },
  },
});

const LeftIconWrapper = styled('div', {
  color: '$$iconColor',
});

const RightIconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '$$iconColor',
  marginLeft: 'auto',
});

const Spacer = styled('div', {
  width: '$space$3',
});

export const MenuItem = ({
  label,
  // testId,
  disabled,

  action = () => {},
  afterAction = () => {},
  leftIcon,
  rightIcon,
  ...props
}: MenuItemProps & React.ComponentProps<typeof StyledItem>) => {
  const { close } = usePopoverContext();

  const maybeAction = async (e: any) => {
    // detect middle click
    // if (e.button === 1) {
    //   afterAction();
    //   return;
    // }

    if (disabled) {
      return;
    }

    await close();
    action();
  };

  return (
    <StyledItem
      onClick={maybeAction}
      {...props}
      disabled={disabled}
      tabIndex={disabled ? -1 : 0}
    >
      {leftIcon ? (
        <>
          <LeftIconWrapper>{leftIcon}</LeftIconWrapper>
          <Spacer />
        </>
      ) : null}
      {label}
      {rightIcon ? (
        <>
          <Spacer />
          <RightIconWrapper>{rightIcon}</RightIconWrapper>
        </>
      ) : null}
    </StyledItem>
  );
};

export const MenuItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});
