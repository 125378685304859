import * as React from 'react';
import { HomeIcon } from 'naan/icons/home-icon';
import { FavoriteFilledIcon } from 'naan/icons/favorite-filled-icon';
import { EduIcon } from 'naan/icons/edu-icon';
import {
  LinkNavItem,
  NavBottomAnchor,
  NavContainer,
  NavLogoWrapper,
} from 'components/ui/navigation/responsive-navigation';
import { classroomsPath, discoverPath, favoritesPath } from './path-helpers';
import { JwLogo } from 'components/branding/jw-logo';
import { TeachPopoverMenu } from './nav-popover-menu';
import { LearnNavButton } from './external-nav-items';

import __ from 'core/lib/localization';

export const TeachNavigation = () => {
  return (
    <NavContainer presentation={'classroom'}>
      <NavLogoWrapper>
        <JwLogo subBrand="classrooms" presentation="colorOnDark" />
      </NavLogoWrapper>
      <LinkNavItem
        to={classroomsPath()}
        // beforeNavigate={clearSavedScrollPosition}
        icon={<EduIcon />}
        label={__('My classes', 'myClasses')}
      />
      <LinkNavItem
        to={discoverPath()}
        // beforeNavigate={clearSavedScrollPosition}
        icon={<HomeIcon />}
        label={__('Discover', 'discover')}
      />
      <LinkNavItem
        to={favoritesPath()}
        // beforeNavigate={clearSavedScrollPosition}
        icon={<FavoriteFilledIcon />}
        label={__('Favorites', 'favorites')}
      />

      <NavBottomAnchor>
        <LearnNavButton flavor="dark" />
        <TeachPopoverMenu />
      </NavBottomAnchor>
    </NavContainer>
  );
};
