import * as DialogPrimitive from '@radix-ui/react-dialog';
import { keyframes, styled, darkThemeSelector } from 'naan/stitches.config';

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.75)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const slideUp = keyframes({
  '0%': { transform: 'translateY(100%)' },
  '100%': { transform: 'translateY(0)' },
});

const slideLeft = keyframes({
  '0%': { transform: 'translateX(100%)' },
  '100%': { transform: 'translateX(0)' },
});

const css = {
  $$radius: '$radii$large',
  $$maxWidth: '360px',
  $$maxHeight: '800px',
  backgroundColor: '$white',
  [darkThemeSelector]: {
    backgroundColor: '$gray-50',
  },
  borderRadius: '$$radius',
  // boxShadow: `0px 1px 4px $colors$black-alpha-20`,
  shadowStyle: 'medium',
  position: 'fixed',
  top: 'calc(50% + (var(--sat) / 2) - (var(--sab) / 2))',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(calc(100% - 32px), $$maxWidth)',
  maxHeight: 'min(calc(100% - 32px - var(--sat) - var(--sab)), $$maxHeight)',
  display: 'flex',
  flexDirection: 'column',

  // padding: 16,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 0.5s cubic-bezier(0.16, 1, 0.3, 1)`,
    willChange: 'transform',
  },
  '&:focus': { outline: 'none' },

  variants: {
    flavor: {
      normal: {},
      sheet: {
        backgroundColor: '$white',
        borderRadius: '$$radius $$radius 0 0',
        // boxShadow: `0px 1px 4px $colors$black-alpha-20`,
        shadowStyle: 'medium',
        position: 'fixed',
        top: 'auto',
        bottom: '0',
        left: '0',
        transform: 'translate(0,0)',
        paddingBottom: 'var(--sab)',
        width: '100%',
        maxHeight:
          'min(calc(100% - 32px - var(--sat) - var(--sab)), $$maxHeight)',
        display: 'flex',
        flexDirection: 'column',
        '@media (prefers-reduced-motion: no-preference)': {
          animation: `${slideUp} 0.4s`,
          willChange: 'transform',
        },
      },
      side: {
        backgroundColor: '$white',
        borderRadius: 0,
        shadowStyle: 'medium',
        position: 'fixed',
        top: '0',
        bottom: '0',
        right: '0',
        left: 'auto',

        transform: 'translate(0,0)',
        width: 'min(300px, 100% - 32px)',
        height: '100%',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        '@media (prefers-reduced-motion: no-preference)': {
          animation: `${slideLeft} 0.4s`,
          willChange: 'transform',
        },
      },
    },
  },
};

export const Content = styled(DialogPrimitive.Content, css);
export const HtmlContent = styled('div', css);
