import * as React from 'react';
import { AvatarIcon } from 'naan/icons/avatar-icon';
import { PopoverNavHeader } from 'components/ui/navigation/responsive-navigation';
import { MenuItem } from './menu-item';
import { SignOutIcon } from '@naan/icons/sign-out-icon';
import { Text } from 'naan/primitives/text';
import { useNavigate } from 'react-router-dom';
import { logoutPath, profileHomePath } from '../path-helpers';
import { AppFactory } from '@app/app-factory';
import {
  clearSavedScrollPosition,
  intentionallyResetAllScrollStorageKeys,
} from 'lib/scroll-utils/deep-scroll-restoration';
import { SharedMenuItems } from './shared-items';
import { NavPopoverMenu } from './nav-popover-menu';
import { AccountMenuTrigger } from './account-trigger';
import { LearnNavButton } from '../external-nav-items';

import __ from 'core/lib/localization';

export const TeachPopoverMenu = () => {
  const { accountData } = AppFactory.root.userManager;

  const navigate = useNavigate();

  const goto = React.useCallback(
    (to: string) => () => {
      clearSavedScrollPosition(to);
      navigate(to);
    },
    [navigate]
  );

  const handleLogout = React.useCallback(() => {
    navigate(logoutPath());
    intentionallyResetAllScrollStorageKeys();
  }, [navigate]);

  return (
    <NavPopoverMenu trigger={<AccountMenuTrigger />}>
      <PopoverNavHeader>
        <Text textStyle="small-heading">{accountData.name}</Text>
        <Text color="textSecondary" textStyle="small-text">
          {accountData.email}
        </Text>
      </PopoverNavHeader>
      <MenuItem
        label={__('Manage my account', 'manageMyAccount')}
        leftIcon={<AvatarIcon />}
        presentation="navItem"
        action={goto(profileHomePath())}
      />
      <SharedMenuItems />
      <MenuItem
        label={__('Sign out', 'signOut')}
        leftIcon={<SignOutIcon />}
        presentation="navItem"
        action={handleLogout}
      />
      <LearnNavButton flavor="normal" />
    </NavPopoverMenu>
  );
};
