import { useSpring } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';

// Minimum distance in pixels required for a swipe gesture to be recognized
// Used for slower, more deliberate swipes
// Higher values prevent accidental triggers from small movements
const SWIPE_THRESHOLD = 280;

// Minimum velocity (speed) required for a swipe to be considered "quick"
// Measured in pixels per millisecond
// 0.5 means user must swipe at least half a pixel per millisecond
const VELOCITY_THRESHOLD = 0.5;

// Alternative shorter distance threshold for quick swipes
// Only applies when swipe velocity is above VELOCITY_THRESHOLD
// Allows fast swipes to work with less distance traveled
const VELOCITY_SWIPE_THRESHOLD = 140;

export interface UseSwipeGestureProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export const useSwipeGesture = ({
  isOpen,
  onOpenChange,
}: UseSwipeGestureProps) => {
  const [springs, api] = useSpring(() => ({
    from: { x: 0 },
    config: { tension: 300, friction: 30 },
  }));

  const bind = useGesture(
    {
      onDrag: ({
        movement: [mx],
        down,
        direction: [xDir],
        velocity: [vx],
        cancel,
      }) => {
        // Only handle swipes when menu is open
        if (isOpen) {
          // Only handle right swipes (to close)
          if (xDir > 0) {
            if (
              mx > SWIPE_THRESHOLD ||
              (vx > VELOCITY_THRESHOLD && mx > VELOCITY_SWIPE_THRESHOLD)
            ) {
              onOpenChange(false);
              cancel?.();
            } else {
              api.start({ x: down ? mx : 0, immediate: down });
            }
          }
        }
      },
      onDragEnd: () => {
        api.start({ x: 0 });
      },
    },
    {
      drag: {
        from: () => [springs.x.get(), 0],
        filterTaps: true,
        bounds: { left: 0, right: SWIPE_THRESHOLD }, // Only allow dragging right
        rubberband: true,
      },
    }
  );

  return { springs, bind };
};
