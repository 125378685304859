import * as React from 'react';
import { darkThemeSelector, styled } from '@naan/stitches.config';
import { l2l1Url } from 'components/nav/path-helpers';
import { JwSymbol } from 'components/branding/jw-symbol';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';
import { appConfig } from 'app/env';
import { Button } from '@naan/primitives/button';
import { Link } from '@naan/primitives/text';
import * as motion from 'motion/react-client';
import { AnimatePresence } from 'motion/react';

const { showEnEsCatalogChoice } = appConfig;

const Wrapper = styled('div', {
  background: '$blue-800',
  [darkThemeSelector]: {
    background: '$blue-300',
  },
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: 'var(--sab)',
  paddingTop: 'var(--sat)',

  '& > .inner': {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0 32px',
    width: 'min(100% - 48px, 360px)',

    '& .logo': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 40,

      '& img': {
        width: 151,
      },
    },
  },

  '& .options': {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },

  '& .next-step': {
    width: '100%',
    // background: 'red',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    gap: 8,
    height: 0,
    transition: 'height 0.3s ease-out, margin 0.3s ease-out',
    borderRadius: 8,
    '&:has(.link-button-group)': {
      height: 48,
      marginTop: 40,
    },
    '& .link-button-group': {
      gridColumn: '1 / 2',
      gridRow: '1 / 2',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
  },
});

const RadioButtonWrapper = styled('label', {
  display: 'flex',
  flexDirection: 'row',
  gap: 12,
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: 16,
  borderRadius: 8,
  background: '$colors$globalBlack-alpha-10',
  color: 'white',

  '&:has(input[type="radio"]:checked)': {
    boxShadow:
      'inset 0 0 0 4px color-mix(in srgb, $colors$teal-500, transparent 50%)',
  },

  '&:hover, &:has(input[type="radio"]:focus-within)': {
    background: '$colors$globalBlack-alpha-20',
  },

  '& input[type="radio"]': {
    '&:focus-visible': {
      outline: '2px solid $colors$globalWhite-alpha-10',
    },
    appearance: 'none',
    background: 'transparent',
    border: '2px solid $colors$globalWhite-alpha-30',
    width: 24,
    height: 24,
    borderRadius: '100%',
    position: 'relative',
    '&:checked': {
      borderColor: '$colors$teal-500',

      '&:after': {
        left: 2,
        top: 2,
        position: 'absolute',
        content: '""',
        display: 'block',
        width: 16,
        height: 16,
        background: '$colors$teal-500',
        borderRadius: '100%',
      },
    },
  },
});

const l1Options = ['es', 'pt', 'en'] as const;
type l1s = typeof l1Options[number];

type l2s = 'en' | 'es';

const RadioButton = ({
  value,
  label,
  l1,
  setL1,
}: {
  value: l1s;
  label: string;
  l1: l1s;
  setL1: (l1: l1s) => void;
}) => {
  return (
    <RadioButtonWrapper>
      <input
        type="radio"
        name="l1"
        value={value}
        onChange={() => setL1(value)}
        checked={l1 === value}
      />
      <span className="label">{label}</span>
    </RadioButtonWrapper>
  );
};

const LinkButtonGroup = ({
  show = true,
  children,
}: {
  show: boolean;
  children: React.ReactNode;
}) => (
  <AnimatePresence mode="wait">
    {show && (
      <motion.div
        className="link-button-group"
        initial={{ opacity: 0, y: -24, scale: 1 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, y: 40, scale: 0.9 }}
        transition={{ duration: 0.3, ease: 'easeOut' }}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);

const LinkButton = ({ url, label }: { url: string; label: string }) => (
  <Link href={url}>
    <Button
      label={label}
      presentation="teal"
      size="large"
      fullWidth
      rightIcon={<ArrowRightIcon />}
    />
  </Link>
);

// function getBrowserLanguage() {
//   const language = navigator.language.split('-')[0];
//   if (l1Options.includes(language as l1s)) {
//     return language as l1s;
//   }
//   return 'en';
// }

export const L2Choose = () => {
  const Url = new URL(window.location.href);
  const params = new URLSearchParams(Url.search);
  const currentl1 = params.get('l1') as l1s;

  if (currentl1) {
    Url.searchParams.delete('l1');
    window.history.replaceState({}, '', Url.toString());
  }

  const [l1, setL1] = React.useState<l1s | null>(currentl1);

  const makeUrl = React.useCallback(
    (l2: l2s) => {
      return l2l1Url({ l2, l1 });
    },
    [l1]
  );

  return (
    <Wrapper>
      <div className="inner">
        <div className="logo">
          <JwSymbol
            presentation="colorOnDark"
            css={{ width: 'auto', height: 'auto' }}
          />
        </div>
        <div className="options">
          <RadioButton
            value="en"
            label="I speak English"
            l1={l1}
            setL1={setL1}
          />

          <RadioButton
            value="pt"
            label="Eu falo português"
            l1={l1}
            setL1={setL1}
          />

          {showEnEsCatalogChoice ? (
            <RadioButton
              value="es"
              label="Hablo español"
              l1={l1}
              setL1={setL1}
            />
          ) : null}
        </div>
        <div className="next-step">
          <LinkButtonGroup show={l1 === 'en'}>
            <LinkButton url={makeUrl('es')} label={'Start learning Spanish'} />
          </LinkButtonGroup>
          <LinkButtonGroup show={l1 === 'pt'}>
            <LinkButton
              url={makeUrl('en')}
              label={'Começar a aprender inglês'}
            />
          </LinkButtonGroup>

          <LinkButtonGroup show={l1 === 'es'}>
            <LinkButton
              url={makeUrl('en')}
              label={'Comienza a aprender inglés'}
            />
          </LinkButtonGroup>
        </div>
      </div>
    </Wrapper>
  );
};
