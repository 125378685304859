import * as React from 'react';
import {
  ResponsiveGridLayout,
  ResponsiveTopSafeAreaDesktop,
  ResponsiveNavContainer,
  ResponsiveMainContainer,
  ResponsiveParentContainer,
} from 'components/global-layout';
import { TeachNavigation } from 'components/nav/teach-navigation';

// import { observer } from 'mobx-react';
// import { MessageService } from 'common/notifications/message-service';
// import { GlobalMessage } from 'naan/global-messages';
import { AppFactory } from 'app/app-factory';
import { styled } from 'naan/stitches.config';
import { Navigate } from 'react-router-dom';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { learnHomePath } from 'components/nav/path-helpers';
// import { LocaleCode } from '@utils/util-types';

// export const GlobalMessagesTarget = observer(() => {
//   const currentMessage = AppFactory.messageService.current;

//   if (!currentMessage) {
//     return null;
//   }

//   return (
//     <GlobalMessage
//       type={currentMessage.type}
//       message={currentMessage}
//       onDismiss={MessageService.clear}
//     />
//   );
// });

const MessageLayout = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  background: 'blue',
  height: '100%',
});

MessageLayout.displayName = 'MessageLayout';

export const TeachLayout: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  // const { l2 } = useParams<{ l2: LocaleCode }>();

  const { classroomEnabled, authenticated } = AppFactory.root.userManager;

  if (!classroomEnabled || !authenticated) {
    return <Navigate to={learnHomePath()} replace />;
  }

  // capture state for when returning from account screen
  ReturnNavState.appMode = 'teach';
  // ReturnNavState.l2 = l2;
  // ReturnNavState.setL2(l2);

  // const { userData } = AppFactory.root.userManager;
  // userData.selectL2(l2);

  return (
    <ResponsiveParentContainer>
      <ResponsiveGridLayout className="x-global-grid">
        <ResponsiveTopSafeAreaDesktop />
        <ResponsiveMainContainer>{children}</ResponsiveMainContainer>
        <ResponsiveNavContainer>
          <TeachNavigation />
        </ResponsiveNavContainer>
      </ResponsiveGridLayout>
    </ResponsiveParentContainer>
  );
};
