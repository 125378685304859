import * as React from 'react';
import { animated } from '@react-spring/web';
import { Content as DialogContent } from '@radix-ui/react-dialog';
import { keyframes, styled, darkThemeSelector } from 'naan/stitches.config';
import { useSwipeGesture, UseSwipeGestureProps } from './use-swipe-gesture';
import { elementIds } from 'components/dom-utils/element-ids';
const slideLeft = keyframes({
  '0%': {
    transform: 'translateX(100%)',
    boxShadow:
      '0px 1px 6px 0px rgba(0, 0, 0, 0), 0 0 0 5000px rgba(0, 0, 0, 0)',
  },
  '100%': {
    transform: 'translateX(0)',
    boxShadow:
      '0px 1px 6px 0px rgba(0, 0, 0, 0.20), 0 0 0 5000px rgba(0, 0, 0, 0.20) ',
  },
});

const slideRight = keyframes({
  '0%': {
    transform: 'translateX(0)',
    boxShadow:
      '0px 1px 6px 0px rgba(0, 0, 0, 0.20), 0 0 0 5000px rgba(0, 0, 0, 0.20) ',
  },
  '100%': {
    transform: 'translateX(100%)',
    boxShadow:
      '0px 1px 6px 0px rgba(0, 0, 0, 0), 0 0 0 5000px rgba(0, 0, 0, 0) ',
  },
});

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(12px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 1, transform: 'translateY(0)' },
  '100%': { opacity: 0, transform: 'translateY(12px)' },
});

/**
 * This is the regular flavor of the Content wrapper component.
 * It doesn't detect swipe gestures.
 */
export const Content = styled(DialogContent, {
  $$radius: '$radii$large',
  backgroundColor: '$white',
  [darkThemeSelector]: {
    backgroundColor: '$gray-50',
  },
  borderRadius: '0',
  boxShadow:
    '0px 1px 6px 0px rgba(0, 0, 0, 0.20), 0 0 0 5000px rgba(0, 0, 0, 0.20) ',
  position: 'fixed',
  top: '0',
  bottom: '0',
  right: '0',
  left: 'auto',

  // borderRadius: 0,

  transform: 'translate(0,0)',
  width: 'min(340px, 100% - 48px)',
  height: '100%',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  '@media (prefers-reduced-motion: no-preference)': {
    // animation: `${slideLeft} 0.4s`,
    willChange: 'transform',
    animationName: `${slideLeft}`,
    animationTimingFunction: 'ease-out',
    animationDuration: '0.3s',

    '&[data-state="closed"]': {
      animationFillMode: 'forwards',
      animationName: `${slideRight}`,
      animationDuration: '0.3s',
    },
  },

  '&:focus': { outline: 'none' },

  '@large': {
    right: 'auto',
    left: 8,
    top: 'auto',
    bottom: 72,
    borderRadius: '$$radius',
    width: 300,
    boxShadow:
      '0px 1px 6px 0px rgba(0, 0, 0, 0.20), 0 0 0 5000px rgba(0, 0, 0, 0.10)',

    // width: 'auto',
    height: 'auto',
    // transform: 'translate(0,0)',
    '@media (prefers-reduced-motion: no-preference)': {
      animationName: `${slideUpAndFade}`,
      animationTimingFunction: 'ease-out',
      animationDuration: '0.3s',
      willChange: 'transform',
      '&[data-state="closed"]': {
        animationName: `${slideDownAndFade}`,
        animationDuration: '0.3s',
      },
    },
  },
});

const AnimatedContent = styled(animated(Content), {
  // ... existing styles from Content ...
});

interface SwipeableContentProps extends UseSwipeGestureProps {
  children: React.ReactNode;
}

/**
 * This is the swipeable flavor of the Content wrapper component.
 * It detects swipe gestures and animates the content in and out.
 */
export const SwipeableContent = ({
  children,
  isOpen,
  onOpenChange,
}: SwipeableContentProps) => {
  const { springs, bind } = useSwipeGesture({ isOpen, onOpenChange });

  return (
    <AnimatedContent
      {...bind()}
      style={{
        transform: springs.x.to((x: number) => `translateX(${x}px)`),
        touchAction: 'none',
      }}
      id={elementIds.POPOVER_CONTENT}
    >
      {children}
    </AnimatedContent>
  );
};
