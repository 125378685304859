import { markdownListStyles } from '@naan/global-styles';
import { globalCss } from 'naan/stitches.config';
import { simulateSafeAreas } from 'native-support/simulate-insets';
//@ts-expect-error
import { normalize } from 'normalize-stitches';

const boxSizing = {
  '*, *:before, *:after': {
    boxSizing: 'border-box',
  },
};

const globalStyles = ({ sat, sab }: { sat?: number; sab?: number }) =>
  globalCss({
    ...normalize,
    ...boxSizing,
    ...simulateSafeAreas,
    '@font-face': [
      {
        fontFamily: 'larsseit-medium',
        src: `
      url("/assets/fonts/5454002/7a60ab9e-f836-4a67-893d-81a589724598.eot?#iefix") format("eot"),
      url("/assets/fonts/5454002/4cb7676d-9352-4367-9e09-4643861168a8.woff2") format("woff2"),
      url("/assets/fonts/5454002/da0e4157-a84a-4e59-bf79-85090e987cd9.woff") format("woff"),
      url("/assets/fonts/5454002/39916207-e1d1-4b02-ad96-3947023333fb.ttf") format("truetype")
      `,
      },
      {
        fontFamily: 'larsseit-bold',
        src: `
      url("/assets/fonts/5454014/5ebbda2e-6878-4494-ab4d-112eeea23ac0.eot?#iefix") format("eot"),
      url("/assets/fonts/5454014/38f62b25-9bb5-4b8f-ab6d-8a5d73286ec1.woff2") format("woff2"),
      url("/assets/fonts/5454014/5fb439fe-d913-4b7d-9727-20ba5bfe48ff.woff") format("woff"),
      url("/assets/fonts/5454014/7b8b3e80-242b-4f48-9ed6-d0dc4175a61c.ttf") format("truetype")`,
      },
      {
        fontFamily: 'permanent-marker',
        src: `
      url("/assets/fonts/Permanent_Marker/PermanentMarker-Regular.woff") format("woff"),
      url("/assets/fonts/Permanent_Marker/PermanentMarker-Regular.woff2") format("woff2"),
      url("/assets/fonts/Permanent_Marker/PermanentMarker-Regular.ttf") format("truetype")`,
      },
    ],

    ':root': {
      '--sat': sat ? `${sat}px` : 'env(safe-area-inset-top)',
      '--sar': 'env(safe-area-inset-right)',
      '--sab': sab ? `${sab}px` : 'env(safe-area-inset-bottom)',
      '--sal': 'env(safe-area-inset-left)',
    },
    /* CSS specific to standalone PWA */
    '@media all and (display-mode: standalone)': {
      /* CSS specific to iOS devices */
      '@supports (-webkit-touch-callout: none)': {
        // we target #root rather than root here since the normal CSS cascade doesn't work for CSS-in-JS
        '#root': {
          '--sab': `max(calc(${sab}px - 8px), 0)`,
        },
      },
    },

    '.__no-scroll': {
      overflow: 'hidden',
    },

    '.__with-nag .site-footer': {
      paddingBottom: '56px',
    },

    html: {
      height: '100%',
      $$tabbarHeight: '60px',
      $$sidebarWidth: '240px',
      $$maxWidth: '960px',

      '@large': {
        $$sidebarWidth: '248px',
      },
    },
    body: {
      height: '100%',
      margin: 0,
      textStyle: 'body',
      color: '$textPrimary',
      textSizeAdjust: '100%',
      overscrollBehaviorY: 'contain',
      minHeight: '-webkit-fill-available',
      WebkitFontSmoothing: 'antialiased',
      backgroundColor: '$white',
    },
    '#root': {
      height: '100%',
    },
    '*': {
      margin: 0,
      padding: 0,
      border: 'none',
    },
    table: {
      borderCollapse: 'collapse',
      borderSpacing: '0',
    },
    svg: {
      display: 'block',
    },
    img: {
      display: 'block',
    },
    '.fsportal': {
      position: 'fixed',
      inset: 0,
      zIndex: 200,
    },
    '[type="button"]': {
      appearance: 'none',
    },
    'a:has(button)': {
      textDecoration: 'none',
    },
    ...markdownListStyles,
  });

export const SpaGlobalStyles: React.FC<React.PropsWithChildren<unknown>> =
  () => {
    const embedState = window.embedState;
    let params = {};
    if (embedState) {
      params = {
        sat: embedState.insetTop,
        sab: embedState.insetBottom,
      };
    }
    globalStyles(params)();
    return null;
  };
