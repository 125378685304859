import * as React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { darkThemeSelector, keyframes, styled } from 'naan/stitches.config';
import {
  LinkProps,
  useMatch,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom';
import { clearSavedScrollPosition } from 'lib/scroll-utils/deep-scroll-restoration';
import { Content } from '@radix-ui/react-dropdown-menu';
// import { MenuContent } from '@naan/primitives/menus/menu-content';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const MenuContent = styled(Content, {
  display: 'flex',
  flexDirection: 'column',
  background: '$colors$white',
  [darkThemeSelector]: {
    background: '$colors$gray-50',
  },
  shadowStyle: 'medium',
  borderRadius: '$radii$large',
  minWidth: '280px',

  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '500ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});
// const MenuContent = styled(Content, {
//   display: 'flex',
//   flexDirection: 'column',
//   background: '$colors$white',
//   [darkThemeSelector]: {
//     background: '$colors$gray-50',
//   },
//   shadowStyle: 'medium',
//   borderRadius: '$radii$large',
//   minWidth: '280px',

//   '@media (prefers-reduced-motion: no-preference)': {
//     animationDuration: '500ms',
//     animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
//     willChange: 'transform, opacity',
//     '&[data-state="open"]': {
//       '&[data-side="top"]': { animationName: slideDownAndFade },
//       '&[data-side="right"]': { animationName: slideLeftAndFade },
//       '&[data-side="bottom"]': { animationName: slideUpAndFade },
//       '&[data-side="left"]': { animationName: slideRightAndFade },
//     },
//   },
// });

export const NavArrow = styled('div', {
  display: 'none',

  '@medium': {
    display: 'block',
    overflow: 'hidden',
    width: 24,
    height: 16,
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      width: '0',
      height: '0',
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderTop: '12px solid $colors$white',
      filter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2))',
      [darkThemeSelector]: {
        borderTopColor: '$colors$gray-50',
        filter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5))',
      },
    },
  },

  '@large': {
    left: -24,
  },
});

export const NavLogoWrapper = styled('div', {
  paddingLeft: 24,
  paddingTop: 28,
  paddingBottom: 28,
  display: 'none',
  color: '$$logoColor',
  '@large': {
    display: 'block',
  },
});

const NavContainerWrapper = styled('nav', {
  $$logoColor: '$colors$gray-800',
  $$navTextColor: '$colors$gray-400',
  [darkThemeSelector]: {
    $$navTextColor: '$colors$gray-500',
  },
  $$highlightColor: '$colors$teal-500',
  $$backgroundColor: '$colors$gray-25',
  $$height: 'calc( $$tabbarHeight + var(--sab))',
  paddingBottom: 'var(--sab)',
  $$spacing: 0,

  backgroundColor: '$$backgroundColor',
  height: '$$height',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  borderTop: '1px solid $gray-100',
  zIndex: 2,

  flex: 1,
  flexDirection: 'row',
  margin: '0 auto',

  gap: '$$spacing',

  '@medium': {
    $$spacing: '56px',
    justifyContent: 'center',
  },

  '@large': {
    $$height: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    $$spacing: 0,
    borderTop: 'none',
  },

  variants: {
    presentation: {
      classroom: {
        $$backgroundColor: '$colors$gray-800',
        $$navTextColor: '$colors$white',
        $$highlightColor: '$colors$yellow-400',
        $$logoColor: '$colors$white',
        borderTop: 'none',
        [darkThemeSelector]: {
          $$backgroundColor: '$colors$gray-25',
        },
      },
    },
  },
});

export const NavContainer: React.FC<
  React.PropsWithChildren<React.ComponentProps<typeof NavContainerWrapper>>
> = ({ children, ...props }) => (
  <NavContainerWrapper {...props}>{children}</NavContainerWrapper>
);

const NavItemWrapper = styled('span', {
  height: '$$tabbarHeight',
  listStyle: 'none',
  width: 72,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '$$navTextColor',
  textDecoration: 'none',
  paddingTop: '7px',

  '& .label': {
    textStyle: 'tiny-text',
    margin: '2px 0 0',
  },

  '& .icon': {
    lineHeight: 0,
  },

  '@medium': {
    width: 'auto',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 0,
    paddingBottom: 2,
    '& .label': {
      textStyle: 'small-text-bold',
      margin: '0 0 0 10px',
    },
  },

  '@large': {
    height: 'auto',
    width: '100%',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    padding: 12,
    paddingLeft: 24,
    paddingBottom: 12,

    '& .label': {
      textStyle: 'small-heading',
      marginLeft: '12px',
      marginTop: '0',
    },
  },

  variants: {
    active: {
      true: {
        color: '$$highlightColor',
      },
    },
  },
});

export const NavItem = ({
  active,
  icon,
  label,
  onClick,
}: {
  active?: boolean;
  icon: React.ReactElement<any> | number | string;
  label: React.ReactElement<any> | number | string;
  onClick?: () => void;
}) => {
  return (
    <NavItemWrapper onClick={onClick} active={active}>
      <span className="icon">{icon}</span>
      <span className="label">{label}</span>
    </NavItemWrapper>
  );
};

type NavItemProps = React.ComponentProps<typeof NavItem>;
export function LinkNavItem({
  to,
  beforeNavigate,
  ...props
}: Omit<NavItemProps, 'onClick'> &
  Pick<LinkProps, 'to'> & { beforeNavigate?: (to: string) => void }) {
  const navigate = useNavigate();
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  const active = Boolean(match);

  const handleClick = React.useCallback(() => {
    // if (beforeNavigate) {
    //   beforeNavigate(to as string);
    // }
    /// just reset the scroll position always, if there's any for this top level
    clearSavedScrollPosition(to as string);
    navigate(to);
  }, [navigate, to]);

  return <NavItem active={active} onClick={handleClick} {...props} />;
}

export const NavBottomAnchor = styled('div', {
  '& .external': {
    flexDirection: 'column',
    gap: '12px',
    padding: '12px',
    display: 'none',
    '@large': {
      display: 'flex',
    },
  },
  '@large': {
    position: 'fixed',
    bottom: 16,
    left: 0,
    height: 'auto',
    width: '$$sidebarWidth',
  },
});

export const PopoverNavTrigger = styled(DropdownMenu.Trigger, {
  all: 'unset',
});

const PopoverNavHeaderWrapper = styled('div', {
  padding: '14px 16px',
  position: 'relative',

  '&:focus': {
    outline: 'none',
  },
  '&::after': {
    position: 'absolute',
    content: '',
    left: '16px',
    right: '16px',
    bottom: 0,
    height: '1px',
    background: '$colors$gray-100',
  },
});
export const PopoverNavHeader = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <PopoverNavHeaderWrapper tabIndex={0}>{children}</PopoverNavHeaderWrapper>
  );
};

export const ExternalItemWrapper = styled('div', {
  padding: '0 0 20px 24px',
});

export const MenuContentWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <MenuContent
      sideOffset={-6}
      collisionPadding={8}
      css={{ minWidth: '300px' }}
    >
      {children}
      <DropdownMenu.Arrow asChild>
        <NavArrow />
      </DropdownMenu.Arrow>
    </MenuContent>
  );
};
