import * as React from 'react';
import {
  Root,
  Portal,
  Trigger as UnstyledTrigger,
} from '@radix-ui/react-dialog';
import { Content } from './dialog-content';
import { Overlay } from './dialog-overlay';
import { useRestoreFocus } from './use-restore-focus';
import { styled } from '@naan/stitches.config';

type RootProps = React.ComponentProps<typeof Root>;
type ContentProps = React.ComponentProps<typeof Content>;

type ContainerProps = {
  onDismiss?: () => void;
  onEscapeKeyDown?: () => void;
} & Omit<RootProps, 'onOpenChange'> &
  Pick<ContentProps, 'css'>;

type OverlayProps = React.ComponentProps<typeof Overlay>;

const Trigger = styled(UnstyledTrigger, {
  all: 'unset',
});

export const Container: React.FC<
  React.PropsWithChildren<
    ContainerProps & {
      zIndex?: OverlayProps['css']['zIndex'];
      overlayCss?: OverlayProps['css'];
      flavor?: ContentProps['flavor'];
      trigger?: React.ReactNode;
      managedOpenChange?: (open: boolean) => void;
    }
  >
> = ({
  open,
  onDismiss = undefined,
  onEscapeKeyDown = undefined,
  children,
  zIndex = 1001,
  overlayCss,
  trigger,
  managedOpenChange,
  ...props
}) => {
  useRestoreFocus();

  const handleOpenChange = React.useCallback(
    (open: boolean) => {
      managedOpenChange?.(open);
      if (open === false) {
        onDismiss?.();
        // prevFocusElement.current?.focus();
      }
    },
    [managedOpenChange, onDismiss]
  );

  // @armando, I thought this might be a place to control just the pointerDownOutside event behavior
  // but it looks like radix was still directly calling the onOpenChange handler regardless
  // (and I suspect the onInteractOutside handler here is irrelevant)

  // const handleInteractOutside = React.useCallback(() => {
  //   if (dismissOnInteractOutside) {
  //     onDismiss();
  //   }
  // }, [dismissOnInteractOutside, onDismiss]);

  return (
    <Root open={open} onOpenChange={handleOpenChange}>
      {trigger && <Trigger>{trigger}</Trigger>}
      <Portal>
        <Overlay css={{ zIndex, ...overlayCss }}>
          <Content
            onInteractOutside={onDismiss}
            onEscapeKeyDown={onEscapeKeyDown}
            {...props}
          >
            {children}
          </Content>
        </Overlay>
      </Portal>
    </Root>
  );
};
