import * as React from 'react';
import { ArrowTopRightExtraSmallIcon } from 'naan/icons/arrow-top-right-icon';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('button', {
  $$backgroundColor: '$colors$white',
  $$borderColor: '$colors$black-alpha-10',
  $$textColor: '$colors$gray-500',

  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '12px',
  padding: '12px',
  paddingRight: '32px',
  borderRadius: '8px',
  backgroundColor: '$$backgroundColor',
  border: '1px solid $$borderColor',
  color: '$$textColor',
  cursor: 'pointer',
  userSelect: 'none',
  position: 'relative',
  '& .left-icon': {
    display: 'flex',
  },

  '& .label': {
    flex: 1,
    textAlign: 'left',
    textStyle: 'small-text-bold',
  },

  '& .right-icon': {
    display: 'flex',
    position: 'absolute',
    right: '8px',
    top: '8px',
    '& svg': {
      width: 12,
      height: 12,
    },
  },

  variants: {
    flavor: {
      normal: {
        $$backgroundColor: '$colors$white',
        $$borderColor: '$colors$black-alpha-10',
        $$textColor: '$colors$gray-500',
      },
      dark: {
        $$backgroundColor: '$colors$globalBlack-alpha-10',
        $$borderColor: '$colors$globalWhite-alpha-20',
        $$textColor: '$colors$globalWhite',
      },
    },
  },
  defaultVariants: {
    flavor: 'normal',
  },
});

export const ExternalNavButton = ({
  label,
  icon,
  onClick,
  flavor,
}: {
  label: string;
  icon: React.ReactNode;
  onClick?: () => void;
} & React.ComponentProps<typeof Wrapper>) => {
  return (
    <Wrapper onClick={onClick} flavor={flavor}>
      <span className="left-icon">{icon}</span>
      <span className="label">{label}</span>
      <span className="right-icon">
        <ArrowTopRightExtraSmallIcon />
      </span>
    </Wrapper>
  );
};
