import * as React from 'react';
import { AvatarIcon } from 'naan/icons/avatar-icon';
import { NavItem } from 'components/ui/navigation/responsive-navigation';
import { AppFactory } from '@app/app-factory';
import { styled } from '@naan/stitches.config';
import __ from 'core/lib/localization';

const LabelWrapper = styled('div', {
  '&.name': {
    display: 'none',
    '@large': { display: 'block' },
  },
  '&.me': {
    display: 'block',
    '@large': { display: 'none' },
  },
});

export const AccountMenuTrigger = () => {
  const { accountData, authenticated } = AppFactory.root.userManager;

  return (
    <NavItem
      icon={<AvatarIcon />}
      label={
        <>
          <LabelWrapper className="name">
            {authenticated ? accountData.name : __('Account', 'account')}
          </LabelWrapper>
          <LabelWrapper className="me">
            {authenticated ? __('Me', 'me') : __('Account', 'account')}
          </LabelWrapper>
        </>
      }
    />
  );
};
